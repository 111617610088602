<template>
  <div class="wrapper">
    <div class="d-flex flex-row">
      <div class="m-1 text-center">
        <button
          class="btn btn-sm btn-outline-primary shadow-none"
          @click="addFile(2)"
        >
          бланк с цифрой 2
        </button>
        <div class="text-smal">{{ last_upload_file_2 }}</div>
      </div>
      <div class="m-1 text-center">
        <button
          class="btn btn-sm btn-outline-warning shadow-none"
          @click="addFile(1)"
        >
          обычный бланк
        </button>
        <div class="text-smal">{{ last_upload_file_1 }}</div>
      </div>
      <input type="file" id="file" ref="file" @change="upload" />
    </div>
    <div v-if="message">
      <div v-if="message.y" class="alert alert-warning" role="alert">
        {{ message.y }}
      </div>
      <div v-if="message.q" class="alert alert-warning" role="alert">
        <table class="table table-sm">
          <tr>
            <th colspan="3" class="text-center">обновлено</th>
          </tr>
          <tr v-for="(item, idx) in message.q" :key="idx">
            <td>{{ item.s }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.art }}</td>
            <td>{{ item.cod }}</td>
          </tr>
        </table>
      </div>
      <div v-if="message.add" class="alert alert-warning" role="alert">
        <table class="table table-sm">
          <tr>
            <th colspan="3" class="text-center">добавлено</th>
          </tr>
          <tr v-for="(item, idx) in message.add" :key="idx">
            <td>{{ item.name }}</td>
            <td>{{ item.art }}</td>
            <td>{{ item.cod }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPPOSTFILE, HTTPGETNOLOADER } from "@/api";
import { mapMutations } from "vuex";
export default {
  name: "UploadBlank",
  data: () => ({
    message: null,
    type: null,
    last_upload_file_1: null,
    last_upload_file_2: null
  }),
  mounted() {
    this.get_last_upload_files();
  },
  methods: {
    ...mapMutations(["LOAD"]),
    addFile(type) {
      this.type = type;
      document.getElementById("file").value = "";
      const input = document.getElementById("file");
      input.click();
    },
    upload() {
      let url;
      if (this.type === 1) {
        url = "/admin/blank/upload_blank_for_download.php";
      } else {
        url = "/admin/blank/upload_blank.php";
      }
      this.LOAD(true);
      let formData = new FormData();
      formData.append(`file`, this.$refs.file.files[0]);
      HTTPPOSTFILE({
        url: url,
        data: formData
      })
        .then(response => (this.message = response.data))
        .then(() => {
          document.getElementById("file").value = "";
          this.LOAD(false);
        })
        .then(() => this.get_last_upload_files());
    },
    get_last_upload_files() {
      HTTPGETNOLOADER({
        url: "/admin/blank/upload_blank.php",
        params: {
          last_upload_file: 1
        }
      }).then(response => {
        this.last_upload_file_1 = response.data.file_1;
        this.last_upload_file_2 = response.data.file_2;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 10px;
}
#file {
  position: absolute;
  top: -10000px;
}
.alert {
  margin-top: 10px;
}
.text-smal {
  font-size: 0.7em;
}
</style>
